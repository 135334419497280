<template>
    <b-modal
        id="testimonial-create-modal"
        title="Testimonial Create Modal"
        centered
        hide-footer
        size="lg"
        @hidden="reset"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
    >
        <template #modal-title>
        <h2 class="m-0">Testimonial Create</h2>
        </template>
        <validation-observer ref="faqCreateFormValidation">
        <b-form @submit.prevent="validationForm">

            <b-form-group label-for="name">
            <template #label>
                Name <span class="text-danger">*</span>
            </template>
                <b-form-input
                id="name"
                v-model="fullName"
                placeholder="Enter the name"
                required
                @keydown="checkMaxLength($event, 'name', 400)"
                />
            </b-form-group>

            <b-form-group label-for="country">
            <template #label>
                Country <span class="text-danger">*</span>
            </template>
                <b-form-input
                id="country"
                v-model="country"
                placeholder="Enter the country"
                required
                @keydown="checkMaxLength($event, 'country', 400)"
                />
            </b-form-group>
    
            <b-form-group label-for="review">
            <template #label>
                Review <span class="text-danger">*</span>
            </template>
            <b-form-textarea
                id="review"
                v-model="review"
                placeholder="Enter the review"
                rows="6"
                max-rows="10"
                required
            />
            </b-form-group>


            <b-form-group label-for="rating">
            <template #label>
                Rating <span class="text-danger">*</span>
            </template>
            <b-form-select
                id="rating"
                v-model="rating"
                :options="ratingOptions"
                required
            ></b-form-select>
            </b-form-group>

    
            <b-form-group label-for="status">
            <template #label>
                Status <span class="text-danger">*</span>
            </template>
            <b-form-select
                id="status"
                v-model="status"
                :options="options"
                required
            ></b-form-select>
            </b-form-group>

            <validation-provider v-slot="{ errors }" name="Image" rules="required">
          <b-form-group
            class="form-group"
            id="input-group-2"
            label-for="input-2"
            description="Upload an image in PNG, JPG, or JPEG format."
            :invalid-feedback="errors[0]"
          >
            <template #label>
              Image <span class="text-danger">*</span>
            </template>
            <b-form-file
              ref="imageInput"
              id="documents"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              required
              plain
              accept=".png,.jpg,.jpeg"
              v-model="files"
              @change="validateFileType($event)"
            ></b-form-file>
          </b-form-group>
        </validation-provider>
    
            <b-form-group class="mt-2 text-right">
            <b-button type="submit" variant="primary" pill class="mr-1">
                Save
            </b-button>
            <b-button
                type="reset"
                variant="outline-secondary"
                @click="reset"
                pill
            >
                Reset
            </b-button>
            </b-form-group>
        </b-form>
        </validation-observer>
    </b-modal>
    </template>
      
      <script>
      import { mapActions } from "vuex";
      import { required } from "@validations";
      import { ValidationProvider, ValidationObserver } from "vee-validate";
      import { BButton, BForm, BFormGroup, BFormInput, BFormTextarea, BFormSelect } from "bootstrap-vue";
      import util from "@/util.js";
      
      export default {
        components: {
          ValidationProvider,
          ValidationObserver,
          BButton,
          BForm,
          BFormGroup,
          BFormInput,
          BFormTextarea,
          BFormSelect,
        },
        data() {
          return {
            required,
            question: "",
            answer: "",
            fullName: "",
            review: "",
            rating: "",
            country: "",
            status: "",
            files : null,

            options: [
              { value: "", text: "-- Select --" },
              { value: true, text: "Enable" },
              { value: false, text: "Disable" },
            ],
            ratingOptions: [
              { value: "", text: "-- Select --" },
              { value: "1", text: "1 Star" },
              { value: "2", text: "2 Stars" },
              { value: "3", text: "3 Stars" },
              { value: "4", text: "4 Stars" },
              { value: "5", text: "5 Stars" },
            ],
          };
        },
        methods: {
          ...mapActions({
            createTestimonial: "appData/createTestimonial",
          }),
          checkMaxLength(event, fieldName, maxLength) {
            if (
              event.target.value.length > maxLength &&
              event.inputType !== "deleteContentBackward" &&
              event.inputType !== "deleteContentForward"
            ) {
              event.preventDefault();
              this[fieldName] = event.target.value.slice(0, maxLength);
            } else {
              this[fieldName] = event.target.value;
            }
          },
          async validationForm() {
            const success = await this.$refs.faqCreateFormValidation.validate();
            if (success) {
              await this.submit();
            }
          },
          async submit() {
            try {

                const formData = new FormData();
                formData.append("full_name", this.fullName);
                formData.append("review", this.review);
                formData.append("rating", this.rating);
                formData.append("country", this.country);
                formData.append("status", this.status);
                formData.append("image", this.files);



            //   const formData = {
            //     full_name: this.fullName,
            //     country: this.country,
            //     review: this.review,
            //     rating: this.rating,
                
            //     status: this.status,
            //   };
              const res = await this.createTestimonial(formData);
              if (res.status === 200) {
                this.$swal({
                  title: "Testimonial created successfully",
                  icon: "success",
                });
                this.reset();
                this.$nextTick(() => {
                  this.$bvModal.hide("testimonial-create-modal");
                });
                this.$emit("modalClosed");
              }
            } catch (error) {
              this.displayError(error);
            }
          },
          reset() {
            this.fullName = "";
            this.review = "";
            this.country = ""; 
            this.rating = ""; 
            this.files = null;
            this.status = ""


          },
          validateFileType(event) {
            const file = event.target.files[0];
            if (!file) return;

            const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
            if (!allowedTypes.includes(file.type)) {
                event.target.value = null;
                if (this.$refs.imageInput) {
                this.$refs.imageInput.$el.setCustomValidity(
                    "Please upload a PNG, JPG, or JPEG image file."
                );
                this.$refs.imageInput.$el.reportValidity();
                }
            } else {
                if (this.$refs.imageInput) {
                this.$refs.imageInput.$el.setCustomValidity("");
                }
            }
        },
          
        },
      };
      </script>
      
      <style></style>
      