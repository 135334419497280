<template>
<div>
    <div class="text-right mb-1">
        <b-button
            variant="primary"
            pill
            @click="createTestimonial"
            v-if="hasPermission('create_testimonial')"
        >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Create</span>
        </b-button>
    </div>

    <b-card>

        <!-- Filters Below  -->
      <b-row class="mb-1" align-v="center">
        <b-col md="7">
          <div v-if="searchType">
            <b-form-group
              label="Name"
              label-for="name"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Name"
              />
            </b-form-group>
            
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- Filters above  -->
        <b-table
            responsive="sm"
            :fields="fields"
            :items="testimonials"
            details-td-class="m-0 p-0"
            small
            :per-page="perPage"
        >
            <template #cell(serial)="row">
                {{ (currentPage - 1) * perPage + row.index + 1 }}
            </template>

            <template #cell(review)="row">
                {{ truncate(row.item.review, 30) }}
            </template>

            <template #cell(rating)="row">
                {{row.item.rating}} stars
            </template>
           
            <template #cell(created_at)="row">
                {{ row.item.created_at }}
            </template>
            <template #cell(updated_at)="row">
                {{ row.item.updated_at }}
            </template>
            <template #cell(created_by_data)="row">
                {{ row.item.created_by }}
            </template>
            <template #cell(updated_by_data)="row">
                {{ row.item.updated_by }}
            </template>
            <template #cell(manage)="row">
                <b-button
                    variant="info"
                    pill
                    size="sm"
                    class="mr-1"
                    @click="editTestimonial(row.item)"
                    v-if="hasPermission('update_testimonial')"
                >
                    Edit
                </b-button>
                <b-button
                    variant="danger"
                    pill
                    size="sm"
                    @click="removeTestimonial(row.item)"
                    v-if="hasPermission('delete_testimonial')"
                >
                    Delete
                </b-button>
            </template>
        </b-table>
        <b-pagination
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
        ></b-pagination>
    </b-card>
    <TestimonialCreateModal
        @modalClosed="onModalClosed"
        :key="`create-${testimonialCreateModalCount}`"
    />
    <TestimonialEditModal
        :testimonial="testimonial"
        @modalClosed="onModalClosed"
        :key="`edit-${testimonialEditModalCount}`"
    />
</div>
</template>
    
  
<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import TestimonialCreateModal from "@/components/admin/testimonial/TestimonialCreateModal.vue";
import TestimonialEditModal from "@/components/admin/testimonial/TestimonialEditModal.vue";

export default {
    components: {
        TestimonialCreateModal,
        TestimonialEditModal,
    },
    data() {
        return {
            fields: [
                { key: "serial", label: "S. No" },
                { key: "full_name", label: "NAME" },
                { key: "country", label: "COUNTRY" },
                { key: "review", label: "REVIEW" },
                { key: "rating", label: "RATING" },
                { key: "created_at", label: "CREATED AT" },
                { key: "created_by_data", label: "CREATED BY" },
                { key: "updated_at", label: "UPDATED AT" },
                { key: "updated_by_data", label: "UPDATED BY" },
                { key: "manage", label: "Action" },
            ],
            currentPage: 1,
            perPage: 10,
            totalItems: 0,
            testimonial: null,
            testimonials: [],
            faqCategories: [],
            testimonialCreateModalCount: 0,
            testimonialEditModalCount: 0,
            searchTypes: [
                { value: 1, name: "Name" },
            ],
            searchType: null,
            name: "",
        };
    },
    mixins: [util],
    created() {
        this.searchType = this.searchTypes[0];
    },
    async mounted() {
        await this.fetchPaginatedData();
    },
    methods: {
        ...mapActions({
            getTestimonials: "appData/getTestimonial",
            deleteTestimonial: "appData/deleteTestimonial",
        }),
        async search() {
            if (this.searchType) {
                switch (this.searchType.value) {
                    case 1:
                        break;
                }
            } else {
                this.name = "";
            }
            this.currentPage = 1;
            await this.fetchPaginatedData();
        },
        
        async fetchPaginatedData() {
            try {
                const res = await this.getTestimonials({
                    pageNumber: this.currentPage,
                    name: this.name ? this.name : "",
                    
                });
                this.testimonials = res.data.results;
                this.totalItems = res.data.count;
                this.perPage = res.data.per_page;
            } catch (error) {
                this.displayError(error);
            }
        },
        createTestimonial() {
            this.testimonialCreateModalCount += 1;
            this.$nextTick(() => {
                this.$bvModal.show("testimonial-create-modal");
            });
        },
        editTestimonial(testimonial) {
            this.testimonial = testimonial;
            this.testimonialEditModalCount += 1;
            this.$nextTick(() => {
                this.$bvModal.show("testimonial-edit-modal");
            });
        },
        async removeTestimonial(testimonial) {
            this.testimonial = testimonial;
            this.$swal({
                title: "Are you sure?",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonText: "Confirm",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then(async (result) => {
                if (result.value) {
                    try {
                        const res = await this.deleteTestimonial({ pk: this.testimonial.id });
                        if (res.status === 200) {
                            this.$swal({
                                title: "Testimonial deleted successfully",
                                icon: "success",
                            });
                            if (
                                (this.totalItems - 1) % this.perPage === 0 &&
                                this.currentPage !== 1
                            ) {
                                this.currentPage -= 1;
                            } else {
                                await this.fetchPaginatedData();
                            }
                        }
                    } catch (error) {
                        console.error("Error deleting Testimonial:", error);
                    }
                }
            });
        },
        async onModalClosed() {
            await this.fetchPaginatedData();
        },
    },
    computed: {
        ...mapGetters({
            hasPermission: "appData/hasPermission",
        }),
    },
    watch: {
        currentPage: async function (val) {
            await this.fetchPaginatedData();
        },
    },
};
</script>

  
  <style>
  .bg1 {
    color: red;
    font-weight: normal;
  }
  </style>
  